import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBreakdownTypeList() {
  // Use toast
  const toast = useToast()
  const refBreakdownTypeListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'name', sortable: false },
    { key: 'additionalInfo', sortable: false },
    { key: 'parentID', label: 'Parent Name', sortable: false },
    { key: 'isActive' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalBreakdownTypes = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const row = ref([])
  const search = ref('')
  const parents = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refBreakdownTypeListTable.value ? refBreakdownTypeListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalBreakdownTypes.value,
    }
  })

  const refetchData = () => {
    refBreakdownTypeListTable.value.refresh()
    GetData()
  }

  watch([currentPage, perPage, search], () => {
    refetchData()
  })

  function GetBreakdownTypes() {
    store.dispatch('pamis-breakdown-type/fetchBreakdownTypes')
      .then(response => {
        const breakdownTypeResult = response.data.filter(x => x.isDelete == false)
        row.value = breakdownTypeResult
        totalBreakdownTypes.value = breakdownTypeResult.length
        parents.value = breakdownTypeResult
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Breakdown Type list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  async function GetData() {
    GetBreakdownTypes()
  }

  GetData()

  return {
    row,
    tableColumns,
    perPage,
    currentPage,
    search,
    totalBreakdownTypes,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refBreakdownTypeListTable,
    parents,
    refetchData,
  }
}
