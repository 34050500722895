import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBreakdownTypes(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/breakdown/breakdownType')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchBreakdowns(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/breakdown/breakdown')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBreakdownType(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/breakdown/breakdownType/Create', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBreakdownType(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`pamis/admin/breakdown/breakdownType/Delete?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBreakdownType(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`pamis/admin/breakdown/breakdownType/Update?id=${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
