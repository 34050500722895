<template>
  <b-sidebar
    id="add-new-breakdown-type-sidebar"
    :visible="isAddNewBreakdownTypeSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-breakdown-type-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ actionType == 0 ? " Add New Breakdown Type" : actionType == 1 ? " Update Breakdown Type" : "Delete Breakdown Type" }}

        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >

          <b-form-group
            label="Parent"
          >
            <treeselect
              id="newParentId"
              v-model="currentParent"
              :multiple="false"
              :options="parents"
            >
              <template #option="{ id, label }">
                <span> {{ label }}</span><br>
                <small class="text-muted"> {{ id }} </small>
              </template>
            </treeselect>

          </b-form-group>

          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="currentName"
              :disabled="actionType==2"
              autofocus
              trim
              placeholder="Name"
            />
          </b-form-group>
          <b-form-group
            label="Additional Info"
            label-for="additional-info"
          >
            <b-form-input
              id="additional-info"
              v-model="currentAdditionalInfo"
              :disabled="actionType==2"
              autofocus
              trim
              placeholder="Additional Info"
            />
          </b-form-group>
          <b-form-checkbox
            id="input-isActive"
            v-model="currentIsActive"
            class="mt-2"
            :disabled="actionType==2"
          >
            IsActive
          </b-form-checkbox>

          <!-- <category-selector :initial-id="currentCategory==null||currentCategory.upperCategory==null?null:currentCategory.upperCategory"  @selectionChanged="selectionChanged" ></category-selector> -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-if="actionType != 2"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ currentBreakdownType==null|| currentBreakdownType.id==null? 'Add':'Update' }}
            </b-button>
            <b-button
              v-if="currentBreakdownType!=null&& currentBreakdownType.id!=null && actionType == 2"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-2"
              variant="outline-danger"
              @click="deleteBreakdownType"
            >
              Delete
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  inject, toRefs, ref, watch,
} from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
// import CategorySelector from '../components/SingleCategorySelector.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    Treeselect
    // CategorySelector
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewBreakdownTypeSidebarActive',
    event: 'update:is-add-new-breakdown-type-sidebar-active',
  },
  props: {
    isAddNewBreakdownTypeSidebarActive: {
      type: Boolean,
      required: true,
    },
    actionType: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      parents: [],
      currentParent: null,
      currentName: null,
      currentAdditionalInfo: null,
      currentIsActive: true,
      currentBreakdownType: {
        Id: null,
        parentID: null,
        name: '',
        isActive: true,
      },
    }
  },

  watch: {
    breakdownTypedata: {
      handler(n, o) {
        console.log(n)
        if (n == null || n == undefined || n.selectedBreakdownType.name == null) {
          this.currentBreakdownType = {
            Id: null,
            parentID: null,
            name: null,
            additionalInfo: null,
            isActive: true,
          }
          this.currentParent = null;
          this.currentName = null;
          this.currentAdditionalInfo = null;
          this.currentIsActive = true;
        } else {
          this.currentBreakdownType = n.selectedBreakdownType;
          this.currentName = n.selectedBreakdownType.name;

          if (n.selectedBreakdownType.parentID != null) {
            this.currentParent = n.selectedBreakdownType.parentID;
          }

          this.currentAdditionalInfo = n.selectedBreakdownType.additionalInfo
          this.currentIsActive = n.selectedBreakdownType.isActive
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchParents()
  },
  methods: {
    removeEmptyChildren(node) {
      if (node.children.length === 0) {
        delete node.children;
      } else {
        node.children.forEach(child => this.removeEmptyChildren(child));
      }
    },
    formatParentChildForTreeSelect(data) {
     const idToData = {};
      const rootItems = [];

      data.forEach(item => {
        const { id, name, parentID } = item;
        const newItem = { id, label: name, children: [] };

        idToData[id] = newItem;

        if (parentID === null) {
          rootItems.push(newItem);
        } else {
          const parentItem = idToData[parentID];
          if (parentItem) {
            if (!parentItem.children) {
              parentItem.children = [];
            }
            parentItem.children.push(newItem);
          }
        }
      });

      return rootItems;
    },
    fetchParents() {
      this.parents = []
      this.$store.dispatch('pamis-breakdown-type/fetchBreakdownTypes')
        .then(result => {
          console.log(result)
          if (result != null) {
            var breakdownTypeList = result.data.filter(x => x.isDelete == false && x.isActive == true)

            var list = this.formatParentChildForTreeSelect(breakdownTypeList);
            list.forEach(node => this.removeEmptyChildren(node));

            this.parents = list
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Workcenter By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    deleteBreakdownType() {
      this.$store.dispatch('pamis-breakdown-type/deleteBreakdownType', { id: this.currentBreakdownType.id })
        .then(result => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-breakdown-type-sidebar-active', false)
          this.notification()
          this.fetchParents()
        })
    },

    submitForm() {
      if (this.currentParent == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Parent  Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else if (this.currentName == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Name Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        let process = 'pamis-breakdown-type/addBreakdownType'

        if (this.currentBreakdownType != null && this.currentBreakdownType.id != null) {
          process = 'pamis-breakdown-type/updateBreakdownType'
        }

        this.currentBreakdownType.parentID = this.currentParent;
        this.currentBreakdownType.name = this.currentName;
        this.currentBreakdownType.additionalInfo = this.currentAdditionalInfo;    
        this.currentBreakdownType.isActive = this.currentIsActive

        this.$store.dispatch(process, this.currentBreakdownType)
          .then(result => {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-breakdown-type-sidebar-active', false)
            this.notification()
            this.fetchParents()
          })
      }
    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Process Successfully',
          icon: 'BellIcon',
        },
      })
    },
  },

  inject: ['breakdownTypedata'],

  setup(props, { emit }) {
    const blankBreakdownTypeData = {
      id: null,
      parentID: null,
      name: null,
    }

    const breakdownTypeData = ref(JSON.parse(JSON.stringify(blankBreakdownTypeData)))
    const resetBreakdownTypeData = () => {
      breakdownTypeData.value = JSON.parse(JSON.stringify(blankBreakdownTypeData))
      emit('refetch-data')
      emit('update:is-add-new-breakdown-type-sidebar-active', false)
    }

    const onSubmit = () => {
      store.dispatch('pamis-breakdown-type/addBreakdownType', currentBreakdownType)
        .then(() => {
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetBreakdownTypeData)

    return {
      breakdownTypeData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-breakdown-type-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
